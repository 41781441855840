<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class=" wp-image-23491 aligncenter" src="@/assets/news/19/1.jpg" alt="图片" width="696" height="296"  sizes="(max-width: 696px) 100vw, 696px"></p>
        <p>87%申请人来自中国</p>
        <p>近期，The Residency Malta Agency（马耳他居留签证管理局）分享了2016年至今马耳永居计划项目新旧政的获批数据，共2,273名主申请人及5,303名附属申请人通过马耳他永居项目（旧政MRVP，新政MPRP）获得马耳他居留许可，其中约87%申请人来自中国。</p>
        <p>具体的年度数据如下：</p>
        <p><img loading="lazy" class="size-full wp-image-23492 aligncenter" src="@/assets/news/19/2.png" alt="图片" width="522" height="288"></p>
        <p>各年度获批主申请人数量</p>
        <p><img loading="lazy" class="size-full wp-image-23493 aligncenter" src="@/assets/news/19/3.png" alt="图片" width="515" height="328"></p>
        <p>约87%获批申请人来自中国</p>
        <p>我们来看一下历年获批人数：</p>
        <p>2016年获批主申人数为29人；</p>
        <p>2017年获批主申人数为92人；</p>
        <p>2018年获批主申人数为264人；</p>
        <p>2019年获批主申人数为400人；</p>
        <p>2020年获批主申人数为988人；</p>
        <p>2021年获批主申人数为500人。</p>
        <p>从历年批准量可以看出，该项目一直呈现稳步上涨的状态，到2020年达到峰值988人，至此，马耳他永居项目成为欧洲投资项目中的一匹黑马。</p>
        <p><img loading="lazy" class=" wp-image-23494 aligncenter" src="@/assets/news/19/4.jpg" alt="图片" width="679" height="241"  sizes="(max-width: 679px) 100vw, 679px"></p>
        <p>2021年获批人数下降，主要原因在新旧政策的过渡。马耳他移民局也承认，马耳他MPRP新政项目的推广速度超过了预期，目前该项目的办理周期基本在4-6个月，此外马耳他也承诺在不影响尽职调查的前提下加速对相关案件进行审理。</p>
        <p>马耳他居留签证管理局CEO CharlesMizzi表示：“虽然在疫情期间推出一个新项目可能看起来不具有战略意义，但对我们来说，这是对价值主张、市场和竞争进行彻底分析的结果。”</p>
        <p>他指出，自去年3月该计划推出以来，已经有了足够的时间来分析市场对马耳他永久居留项目的反应。Charles表示，投资者的兴趣一直很高，并在2021年下半年出现了大幅增长，预计申请人数在不久的将来会达到新的高度。</p>
        <p style="text-align: center;"><span style="color: #0000ff;"><strong>马耳他</strong></span></p>
        <p>马耳他是一个袖珍国，蔚蓝的海水，充足的阳光，金黄的城邦，其面积差不多只有我们香港的三分之一大小。但他的富裕程度却很高，而且是一个集齐申根，欧元，欧盟，英联邦四位一体的国家；是欧洲人的度假胜地、小众蜜月地；更是欧非亚三大洲贸易枢纽、深得隐富者的钟意。</p>
        <p>很多人知道“马耳他”(Malta)这个名字，是因为美剧《权力的游戏》曾在这里壮美的景观之一“蓝窗”取景。然而，随着2017年3月蓝窗的轰然倒塌，许多还没有来得及亲自前往感受它的美丽的小伙伴们心碎不已。</p>
        <p><img loading="lazy" class=" wp-image-23495 aligncenter" src="@/assets/news/19/5.jpg" alt="图片" width="683" height="384"  sizes="(max-width: 683px) 100vw, 683px"></p>
        <p>其实在马耳他这座被称为“地中海心脏”的美丽岛屿上，值得停留的地方不仅仅是蓝窗。这片位于欧洲南端的土地上，还静静地收藏着千年的文明故事。</p>
        <p>居住在这个用丰富色彩所编织的地中海梦境中，也是一种惬意的享受。</p>
        <p>马耳他项目作为欧洲老牌黄金签证项目之一，这么多年来也受到了很多高净值人群青睐，去年，该项目即将迎来重大变动的消息不胫而走，此后便一直牵动人心。当地时间2021年3月26日，马耳他移民局正式公布了“Malta Permanent Residence Programme 简称MPRP”项目的投资要求和细节。新的MPRP项目在投资方式、投资金额、申请费用、申请条件、医疗保险等方面都做了调整。我们再来回顾一下吧。</p>
        <p><span style="color: #0000ff;"><strong>马耳他MPRP项目</strong></span></p>
        <p><span style="color: #0000ff;"><strong>1、投资要求</strong></span></p>
        <p><span style="color: #0000ff;">政府捐款</span></p>
        <p>租房方式：捐款金额5.8万欧元</p>
        <p>购房方式：捐款金额2.8万欧元</p>
        <p><span style="color: #0000ff;">慈善捐款</span></p>
        <p>2000欧元到当地非盈利组织</p>
        <p>购房或租房（至少维持5年）</p>
        <p>购房金额：马耳他主岛35万欧元以上，戈佐岛/马耳他南部30万欧元以上</p>
        <p>租房金额：马耳他主岛最低1.2万欧元以上/年，戈佐岛/马耳他南部最低1万欧元以上/年。</p>
        <p><img loading="lazy" class=" wp-image-23496 aligncenter" src="@/assets/news/19/6.jpg" alt="图片" width="696" height="464"  sizes="(max-width: 696px) 100vw, 696px"></p>
        <p><span style="color: #0000ff;"><strong>2.资产证明</strong></span></p>
        <p><span style="color: #0000ff;">资产证明</span></p>
        <p>主申请人需要证明拥有50万欧元的净资产</p>
        <p>其中15万欧元为存款或其他容易变现的金融资产</p>
        <p>新政对于投资人的资产证明要求进一步降低，能够让更多的申请人满足项目的要求，从而实现赴马耳他生活、定居的目的。（更多详情参考：马耳他永居正式开启新时代！）</p>
        <p><img loading="lazy" class="wp-image-23497 aligncenter" src="@/assets/news/19/7.jpg" alt="图片" width="704" height="469"  sizes="(max-width: 704px) 100vw, 704px"></p>
        <p>整体而言，马耳他新政的办理成本低于之前；其次，取消国债投资要求，投资人一方面不需要担心国债收益和持有期限问题，另一方面可以减少国债开户等相关操作流程，从而缩短项目的办理周期。</p>
        <p><span style="color: #0000ff;"><strong>马耳他MPRP项目投资优势</strong></span></p>
        <p>全家族：一份投资，全家移民<br>
          拿永居：直接获得永久居留卡<br>
          低门槛：无移民监、学历及语言要求<br>
          四位一体：欧盟成员国、申根国、欧元国、英联邦国家</p>
        <p>优环境：官方语言英语，社会治安良好<br>
          畅全球：护照免签英、美、加等180多个国家<br>
          低税率：无全球征税，无遗产税，企业税低至5%</p>
        <p>为资产配置、财富传承、子女教育、全球通行<br>
          为实现家族晋升</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news19",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:19,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>